import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Container, Heading, Wiki } from '@components'
import { WikiType } from '@types'

import { DefaultLayout } from '../layouts/defaultlayout'

export const query = graphql`
  query {
    wiki: directus {
      ...WikiFragment
    }
  }
`

interface Props {
  data: {
    wiki: {
      wiki: Array<WikiType>
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ data }: Props) => (
  <DefaultLayout isDataLoaded>
    <Helmet>
      <title>Hell-O-Chili - Wiki</title>
    </Helmet>
    <Container fullHeight>
      <Heading title="Wiki" subtitle="Feuerscharfe Infos" />
      <Wiki data={data.wiki.wiki} />
    </Container>
  </DefaultLayout>
)
